import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {clientAPI} from "./axiosAnstance";
import axios from "axios";
import {SERVER_URL} from "../../../serverConfig";
import {getAuthHeader} from "../../../jwt/config";
import fileDownload from "js-file-download";





//
export const listAidAdhaDemandeAPI = createAsyncThunk(
  "estivage/listAidAdhaDemandeAPI",
  async (data) => {
    const url = `/api/adha/getDemandes`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);

export const saveDemandeEsivageAPI = createAsyncThunk(
  "estivage/SaveDemandeEstivage",
  async (data) => {
    try {
      const response = await clientAPI.post("/api/estivage/saveDemande", data);
      return response.data;
    } catch (error) {
      return 'il est un probléme au niveau de la API';
    }
  }
);

export const valideDemandeAPI = createAsyncThunk(
  "bourse/valideDemandeAPI",
  async ({ id, action }, thunkAPI) => {
    try {
      const url = `/api/adha/valide/${id}`;
      const response = await clientAPI.post(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const listDemandeFilterAPI = createAsyncThunk(
  "adha/listDemandeFilterAPI",
  async (data) => {
    console.log('le data est :: ',data)
    //const url = `/api/colonie/listColonie`;
    const response = await clientAPI.post("/api/adha/filter",data);
    return response.data;
  }
);
export const listTypeFichierSavedAidAPI = createAsyncThunk(
  "estivage/listTypeFichierSavedAidAPI",
  async (idDemande) => {
    const response = await clientAPI.get("/api/adha/listeTypeFichierSaved/" + idDemande);
    return response.data;
  }
);

export const getFileByDemandeAndTypeAid = createAsyncThunk(
  "adha/getFileByDemandeAndTypeAid",
  async (data) => {
    return axios
      .get(
        SERVER_URL +
        `/api/adha/getFileByDemandeAndType/${data.idDemande}/${data.id_type_fichier}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        if (data.id_type_fichier == 6)
          fileDownload(res.data, data.libelle + ".jpeg");
        else fileDownload(res.data, data.libelle + ".pdf");
      });
  }
);


export const saveFichierEstivageAPI = createAsyncThunk(
  "estivage/saveFichier",
  async (data) => {
    const fichi = JSON.stringify(data.toSave);

    const blob = new Blob([fichi], {
      type: "application/json",
    });

    var formData = new FormData();

    var theFile = data.file;

    formData.append("file", theFile);
    formData.append("fileMeta", blob);
    let token = localStorage.getItem("token");

    const response = axios.post(SERVER_URL + `/api/estivage/saveFichier`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      Accept: "application/json",
    });
    return (await response).data;
  }
);

const AidAdha = createSlice({
  name: "aidAdha",
  initialState: {
    listeDemande : [],
    listeTypeFichierSaved : [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listAidAdhaDemandeAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeDemande = action.payload;
        console.log("la liste des demandes aid adha est ::: ",action.payload)
      })
      .addCase(listTypeFichierSavedAidAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeTypeFichierSaved = action.payload;
        console.log("la liste  ::: ",action.payload)
      })
      .addCase(listDemandeFilterAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listeDemande = action.payload;
        console.log("la liste  ::: ",action.payload)
      })

  },
});


export const {} = AidAdha.actions;

export default AidAdha.reducer;
